import React from 'react';
import styled from '@xstyled/styled-components';
import {
  compose,
  createSystemComponent,
  style,
} from '@xstyled/system';

const width = style({
  prop: 'width',
});

const height = style({
  prop: 'height',
});

const maxWidth = style({
  prop: 'maxWidth',
});

const objectFit = style({
  prop: 'objectFit',
});

const objectPosition = style({
  prop: 'objectPosition',
});

export const styleProps = compose(
  width,
  height,
  maxWidth,
  objectFit,
  objectPosition,
);

const SystemImage = createSystemComponent(React, 'img', styleProps);

const ImageWrapper = styled(SystemImage)`
  display: block;
  ${styleProps}
`;

ImageWrapper.displayName = 'ImageWrapper';

export default ImageWrapper;
