import React from 'react';

import Typography from '../../../../components/Typography/Typography';

export default function ColumnText(props) {
  return (
    <Typography
      color="white.dark"
      variant="paragraph1"
      strong
      {...props}
    />
  );
}
