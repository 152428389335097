import maybeFormatPrefixedMessage from '../i18n/maybeFormatPrefixedMessage';

export default function intlAriaAttrs(intl, intlAriaKey) {
  const bindFormatIntl = maybeFormatPrefixedMessage(intl, intlAriaKey);

  return intlAriaKey ? {
    title: bindFormatIntl('TITLE'),
    'aria-details': bindFormatIntl('ARIA_DETAILS'),
    'aria-roledescription': bindFormatIntl('ARIA_ROLEDESCRIPTION'),
    'aria-label': bindFormatIntl('ARIA_LABEL'),
  } : {};
}
