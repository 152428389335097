export default {
  svgPath: `
    <g clip-path="url(#clip0)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 18a8 8 0 100-16 8 8 0 000 16zm0 2c5.523 0 10-4.477 10-10s-4.477-10-10-10S.5 4.477.5 10s4.477 10 10 10z"/>
    </g>
    <defs>
    <clipPath id="clip0">
        <path d="M.5 0h20v20H.5z"/>
    </clipPath>
    </defs>
  `,
  title: 'Circle',
  viewBox: '0 0 21 20',
};
