import { css } from 'styled-components';

import { nuDSColor } from '../../../styles/themeUtils';
import { tablet768, desktop1024 } from '../../../styles/breakpoints';

const footerMargin = css`
  padding: ${({ compact }) => (compact ? '40px' : '80px')} ${({ theme }) => theme.margin.mobileSmall}px;

  ${tablet768`
    padding-left: ${({ theme }) => theme.margin.tablet}px;
    padding-right: ${({ theme }) => theme.margin.tablet}px;
  `}

  ${desktop1024`
    padding-left: ${({ theme }) => theme.margin.desktop}px;
    padding-right: ${({ theme }) => theme.margin.desktop}px;
  `}
`;

export const sectionStyle = css`
  &:not(:last-child) {
    border-bottom: 1px solid ${nuDSColor('black', 'light')};
  }

  ${footerMargin};
`;
