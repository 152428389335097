import React from 'react';
import PropTypes from 'prop-types';

import { WaveBase } from './styles/WaveBase';

export default function Wave({ id, removeWaveById, ...props }) {
  function handleAnimationEnd() {
    removeWaveById(id);
  }

  return (
    <WaveBase
      {...props}
      onAnimationEnd={handleAnimationEnd}
    />
  );
}

Wave.propTypes = {
  id: PropTypes.number.isRequired,
  removeWaveById: PropTypes.func.isRequired,
  ...WaveBase.propTypes,
};
