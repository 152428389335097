import twitter from './twitter';
import youtube from './youtube';
import xCircle from './xCircle';
import checkCircle from './checkCircle';
import menu from './menu';
import search from './search';
import x from './x';
import pencil from './pencil';
import minus from './minus';
import plus from './plus';
import circle from './circle';
import circleWithFill from './circleWithFill';
import download from './download';
import arrowLeft from './arrowLeft';
import arrowRight from './arrowRight';
import arrowDown from './arrowDown';
import arrowDownRight from './arrowDownRight';
import arrowUp from './arrowUp';
import arrowUpRight from './arrowUpRight';
import avatar from './avatar';
import calendar from './calendar';
import checkbox from './checkbox';
import checkboxFill from './checkboxFill';
import facebook from './facebook';
import linkedin from './linkedin';
import instagram from './instagram';
import login from './login';
import logout from './logout';
import chevronUp from './chevronUp';
import chevronDown from './chevronDown';
import copy from './copy';
import warningCircle from './warningCircle';
import chevronRight from './chevronRight';
import bell from './bell';
import card from './card';
import cardBlocked from './cardBlocked';
import smartphone from './smartphone';
import virtualCard from './virtualCard';
import check from './check';

export default {
  'check-circle': checkCircle,
  'x-circle': xCircle,
  menu,
  x,
  search,
  plus,
  minus,
  pencil,
  circle,
  'circle-with-fill': circleWithFill,
  download,
  'arrow-left': arrowLeft,
  'arrow-right': arrowRight,
  'arrow-down': arrowDown,
  'arrow-down-right': arrowDownRight,
  'arrow-up': arrowUp,
  'arrow-up-right': arrowUpRight,
  avatar,
  calendar,
  'checkbox-fill': checkboxFill,
  checkbox,
  facebook,
  linkedin,
  instagram,
  twitter,
  youtube,
  login,
  logout,
  'chevron-up': chevronUp,
  'chevron-down': chevronDown,
  'chevron-right': chevronRight,
  copy,
  'warning-circle': warningCircle,
  bell,
  card,
  'card-blocked': cardBlocked,
  smartphone,
  'virtual-card': virtualCard,
  check,
};
