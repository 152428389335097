import PropTypes from 'prop-types';
import styled, { css } from '@xstyled/styled-components';

import { nuDSColor } from '../../../styles/themeUtils';

const styleVariantMapStyle = {
  primary: {
    contained: {
      styles: css`
        background: ${nuDSColor('primary')};
        color: ${nuDSColor('white')};
        --rippleLayerColor: ${nuDSColor('white', 'defaultT20')};
      `,
      hoverLayerColor: nuDSColor('white', 'defaultT10'),
      actionLayerColor: nuDSColor('white', 'defaultT20'),
    },
    basic: {
      styles: css`
        background: transparent;
        color: ${nuDSColor('primary')};
        --rippleLayerColor: ${nuDSColor('primary', 'defaultL40')};
      `,
      hoverLayerColor: nuDSColor('primary', 'defaultL10'),
      actionLayerColor: nuDSColor('primary', 'defaultL20'),
    },
  },
  black: {
    contained: {
      styles: css`
        background: ${nuDSColor('black')};
        color: ${nuDSColor('white')};
        --rippleLayerColor: ${nuDSColor('white', 'defaultT20')};
      `,
      hoverLayerColor: nuDSColor('white', 'defaultT10'),
      actionLayerColor: nuDSColor('white', 'defaultT20'),
    },
    basic: {
      styles: css`
        background: transparent;
        color: ${nuDSColor('black')};
        --rippleLayerColor: ${nuDSColor('primary', 'defaultL40')};
      `,
      hoverLayerColor: nuDSColor('primary', 'defaultL10'),
      actionLayerColor: nuDSColor('primary', 'defaultL20'),
    },
  },
  white: {
    contained: {
      styles: css`
        background: ${nuDSColor('white')};
        color: ${nuDSColor('primary')};
        --rippleLayerColor: ${nuDSColor('primary', 'defaultL40')};
      `,
      hoverLayerColor: nuDSColor('primary', 'defaultL10'),
      actionLayerColor: nuDSColor('primary', 'defaultL20'),
    },
    basic: {
      styles: css`
        background: transparent;
        color: ${nuDSColor('white')};
        --rippleLayerColor: ${nuDSColor('white', 'defaultT20')};
      `,
      hoverLayerColor: nuDSColor('white', 'defaultT10'),
      actionLayerColor: nuDSColor('white', 'defaultT20'),
      disabled: css`
        color: ${nuDSColor('white', 'defaultT20')};
      `,
    },
  },
};

const statesVariantMapStyle = {
  disabled: {
    default: css`
      cursor: not-allowed;
      ${({ styleVariant, variant }) => styleVariantMapStyle[styleVariant][variant].disabled};
    `,
    basic: css`
      color: ${nuDSColor('black', 'defaultT20')};
      background: transparent;
    `,
    contained: css`
      color: ${nuDSColor('black', 'defaultT20')};
      background: ${nuDSColor('black', 'defaultT10')};
    `,
  },
  hover: css`
    :before {
      background: ${({ styleVariant, variant }) => styleVariantMapStyle[styleVariant][variant].hoverLayerColor};
    }
  `,
  active: css`
    :before {
      background: ${({ styleVariant, variant }) => styleVariantMapStyle[styleVariant][variant].actionLayerColor};
    }
  `,
};

const variantMapStyle = {
  contained: css`
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    border-radius: 6.25rem;
  `,
  basic: css`
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    border-radius: 6.25rem;
  `,
  iconOnly: css`
    justify-content: center;
    border-radius: 100%;
    width: 3rem;
    height: 3rem;
  `,
};

const displayMapStyle = {
  extended: css`
    display: flex;
    width: 100%;
    margin-bottom: 1rem;
    justify-content: ${({ hasIcon }) => (hasIcon ? 'space-between' : 'center')};
  `,
  inline: css`
    display: inline-flex;
  `,
};

const sizeMapStyle = {
  default: css`
    height: 3rem;
  `,
  small: css`
    height: 2rem;
  `,
};

export const BaseButton = styled.button`
  ${({ variant, isIconOnly }) => (
    isIconOnly
      ? variantMapStyle.iconOnly
      : variantMapStyle[variant])};

  ${({ styleVariant, variant }) => styleVariantMapStyle[styleVariant][variant].styles};
  ${({ size }) => sizeMapStyle[size]};
  ${({ extended }) => (extended ? displayMapStyle.extended : displayMapStyle.inline)};

  cursor: pointer;
  outline: 0;
  align-items: center;
  vertical-align: middle;
  overflow: hidden;
  position: relative;
  border: 0;
  transform: translate3d(0, 0, 0);

  & > svg {
    margin-left: ${({ hasText }) => (hasText ? '0.5rem' : '0')};
  }

  span,
  svg {
    position: relative;
    z-index: 2;
  }

  span,
  svg,
  div,
  :after,
  :before {
    pointer-events: none;
  }

  :before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    transition: 350ms linear;
  }

  :disabled {
    ${({ variant }) => statesVariantMapStyle.disabled[variant]};
    ${statesVariantMapStyle.disabled.default};
  }

  :hover:not(:disabled) {
    ${statesVariantMapStyle.hover};
  }

  :active:not(:disabled),
  :focus:not(:disabled) {
    ${statesVariantMapStyle.active};
  }
`;

BaseButton.displayName = 'BaseButton';

BaseButton.propTypes = {
  extended: PropTypes.bool.isRequired,
  size: PropTypes.oneOf(Object.keys(sizeMapStyle)).isRequired,
  styleVariant: PropTypes.oneOf([...Object.keys(styleVariantMapStyle), '']).isRequired,
  variant: PropTypes.oneOf(Object.keys(variantMapStyle)).isRequired,
};

