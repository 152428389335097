import { useState } from 'react';

export function useRipple() {
  const [waves, setWaves] = useState([]);

  function addWave(
    {
      topPosition = 0,
      bottomPosition = 0,
      leftPosition = 0,
      rightPosition = 0,
    },
    idGenerator,
  ) {
    const waveDiameter = Math.max(topPosition, bottomPosition, leftPosition, rightPosition) * 2;
    const scaleValue = waveDiameter / 10;
    const newWave = {
      id: idGenerator(),
      top: topPosition,
      left: leftPosition,
      scale: scaleValue,
    };
    setWaves([...waves, newWave]);
  }

  function removeWaveById(currentWaveId) {
    setWaves(waves.filter(wave => wave.id !== currentWaveId));
  }

  function addRippleEffectWave(
    {
      startFromMiddle = false,
      baseElementBoundingClientRect,
      mousePosition = {
        clientX: 0,
        clientY: 0,
      },
      idGenerator = Date.now,
    },
  ) {
    const {
      width,
      height,
      top: baseTop,
      left: baseLeft,
      right: baseRight,
      bottom: baseBottom,
    } = baseElementBoundingClientRect;

    if (startFromMiddle) {
      const topPosition = height / 2;
      const leftPosition = width / 2;

      addWave(
        {
          topPosition,
          leftPosition,
        },
        idGenerator,
      );
      return;
    }
    const { clientX, clientY } = mousePosition;
    const topPosition = Math.abs(baseTop - clientY);
    const bottomPosition = Math.abs(clientY - baseBottom);
    const leftPosition = Math.abs(baseLeft - clientX);
    const rightPosition = Math.abs(clientX - baseRight);

    addWave(
      {
        topPosition,
        bottomPosition,
        leftPosition,
        rightPosition,
      },
      idGenerator,
    );
  }

  return {
    waves,
    setWaves,
    addRippleEffectWave,
    removeWaveById,
  };
}
