import React from 'react';
import PropTypes from 'prop-types';

import { NavigationWrapper } from './styles/Navigation';
import { Column } from './styles/Column';
import ColumnTitle from './ColumnTitle';
import ColumnText from './ColumnText';
import ColumnLink from './styles/ColumnLink';

function Navigation({ children, className, ...others }) {
  return (
    <NavigationWrapper className={className} {...others}>
      {children({
        Column,
        ColumnTitle,
        ColumnText,
        ColumnLink,
      })}
    </NavigationWrapper>
  );
}

Navigation.defaultProps = {
  className: undefined,
};

Navigation.propTypes = {
  children: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default Navigation;
