import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { NuDsContext } from '../components/NuDSProvider/NuDSProvider';
import refType from '../utils/ref/refType';
import intlAriaAttrs from '../utils/intl/intlAriaAttrs';

export default function withIntlAriaAttrs(Component) {
  const EnhancedComponent = props => {
    const { intlAriaKey, forwardedRef, ...otherProps } = props;
    const nudsContext = useContext(NuDsContext);

    return (
      <Component
        {...intlAriaAttrs(nudsContext, intlAriaKey)}
        {...otherProps}
        ref={forwardedRef}
      />
    );
  };

  EnhancedComponent.defaultProps = {
    forwardedRef: undefined,
    intlAriaKey: '',
  };

  EnhancedComponent.propTypes = {
    forwardedRef: refType,
    intlAriaKey: PropTypes.string,
  };

  function forwardRef(props, ref) {
    return <EnhancedComponent {...props} forwardedRef={ref} />;
  }

  const name = Component.displayName || Component.name;
  if (name) {
    forwardRef.displayName = `withIntlAriaAttrs(${name})`;
  }

  return React.forwardRef(forwardRef);
}
