import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import Box from '../Box/Box';
import useDidUpdateEffect from '../../utils/hooks/useDidUpdateEffect';

import { CollapseHeader } from './patterns/CollapseHeader/CollapseHeader';
import { CollapseBody } from './patterns/CollapseBody/CollapseBody';

function Collapse({
  tag,
  children,
  className,
  onChange,
  initiallyOpen,
  id,
}) {
  const [isOpen, toggleButton] = useState(initiallyOpen);

  const handleToggleButton = useCallback(() => {
    toggleButton(!isOpen);
  }, [isOpen]);

  const onCallback = (callback, params = {}) => {
    if (callback) {
      callback({ isOpen, ...params });
    }
  };

  useDidUpdateEffect(() => {
    onCallback(onChange);
  }, [isOpen]);

  const collapseId = `collapse-${id}`;

  return (
    <Box
      id={id}
      tag={tag}
      className={className}
      borderTop="2px solid"
      borderTopColor="black"
    >
      {
        React.Children.map(children, child => (
          React.cloneElement(child, {
            isOpen,
            handleToggleButton,
            id: collapseId,
          })
        ))
      }
    </Box>
  );
}

Collapse.defaultProps = {
  tag: 'div',
  className: undefined,
  initiallyOpen: false,
  onChange: () => {},
};

Collapse.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  initiallyOpen: PropTypes.bool,
  onChange: PropTypes.func,
  /** The actual HTML element to be rendered */
  tag: PropTypes.elementType,
};

Collapse.Header = CollapseHeader;
Collapse.Body = CollapseBody;

export default Collapse;
