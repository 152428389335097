import { th, getColor } from '@xstyled/system';
import { useTheme } from '@xstyled/styled-components';

import { isType } from '../utils/isType/isType';
import { isBrowserEnv } from '../utils/env/isBrowserEnv/isBrowserEnv';

import nuDSTheme from './theme';
import { spacing as spacingCalculator } from './spacing/spacing';

export const nuDSTh = (...args) => th(...args);
export const useNuDSTheme = () => useTheme();

export const hasColorInNuDS = (colorName, { theme } = { theme: nuDSTheme }) => {
  const currentColor = theme.colors[colorName];
  return Boolean(currentColor);
};

export function isColorNameCSSDefinition(colorName) {
  const GLOBAL_COLORS = new Set(['inherit', 'currentColor', 'initial', 'unset', 'transparent']);

  return GLOBAL_COLORS.has(colorName);
}

const NEW_COLOR_REGEX = /^\w+\.\w+$/;

export const isLegacyColor = color => !NEW_COLOR_REGEX.test(color);

export function nuDSColor(colorName, colorVariant = 'default', isWarnEnabled = true) {
  return (props = { theme: nuDSTheme }) => {
    const { theme } = props;
    const themeColor = theme.colors[colorName];
    const hasColor = Boolean(themeColor);

    if (!isLegacyColor(colorName)) {
      return getColor(colorName);
    }

    if (!hasColor) {
      if (!isColorNameCSSDefinition(colorName) && isBrowserEnv && isWarnEnabled) {
        console.warn(`Color "${colorName}" doesn't exist in NuDS`);
      }

      return colorName;
    }

    if (isType('string', themeColor)) {
      return themeColor;
    }

    const themeColorVariant = themeColor[colorVariant];
    const hasColorVariant = Boolean(themeColorVariant);

    if (!hasColorVariant) {
      throw new Error(`Variant "${colorVariant}" doesn't exist in ${colorName} on NuDS`);
    }

    return themeColorVariant;
  };
}

export const spacing = spacingCalculator.bind(null, nuDSTheme);

