import { th } from '@xstyled/system';

export const DEFAULT_SPACE = {
  '0x': 0,
  '1x': 4,
  '2x': 8,
  '3x': 12,
  '4x': 16,
  '5x': 20,
  '6x': 24,
  '8x': 32,
  '12x': 48,
  '16x': 64,
  '18x': 72,
  '20x': 80,
  '24x': 96,
  '28x': 112,
  '32x': 128,
  '36x': 144,
  '40x': 160,
};

export const spacing = (nudsTheme, ...spacingValues) => (
  spacingValues.reduce((spacingString, spacingValue) => `${spacingString} ${th.space(spacingValue)({ theme: nudsTheme })}`, '').trim()
);
