import styled from 'styled-components';

import { desktop1024 } from '../../../../../styles/breakpoints';

export const Social = styled.div`
  ${desktop1024`
    display: flex;
    justify-content: flex-end;
    margin-top: 0;
  `}
`;

Social.displayName = 'Social';
