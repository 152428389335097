import React, { StrictMode, createContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@xstyled/styled-components';

import GlobalStyle, { GlobalFonts } from '../GlobalStyle/GlobalStyle';
import defaultTheme from '../../styles/theme';
import { products } from '../../styles/colors/colors';

export const requireImageFunctionDefault = imageSource => imageSource;

export const NuDsContext = createContext({
  locale: '',
  formatMessage: ({ id }) => id,
  routerLinkComponent: undefined,
  backgroundImageFunction: undefined,
  requireImageFunction: requireImageFunctionDefault,
});

export const defaultPrimaryColorScheme = 'brand';

function NuDSProvider({
  locale,
  formatMessage,
  messages,
  routerLinkComponent,
  theme,
  colorScheme,
  children,
  requireImageFunction,
  backgroundImageFunction,
}) {
  const nuDSUpdatedTheme = {
    ...theme,
    colors: {
      ...theme.colors,
      primary: theme.colors[colorScheme],
    },
  };

  return (
    <StrictMode>
      <NuDsContext.Provider
        value={{
          locale,
          formatMessage,
          messages,
          routerLinkComponent,
          requireImageFunction,
          backgroundImageFunction,
        }}
      >
        <ThemeProvider theme={nuDSUpdatedTheme}>
          <>
            <GlobalFonts />
            <GlobalStyle />
            {children}
            <div id="modal-root" />
          </>
        </ThemeProvider>
      </NuDsContext.Provider>
    </StrictMode>
  );
}

export const nuDSProviderShape = {
  backgroundImageFunction: PropTypes.func,
  children: PropTypes.node.isRequired,
  colorScheme: PropTypes.oneOf([...Object.keys(products), defaultPrimaryColorScheme]),
  formatMessage: PropTypes.func,
  locale: PropTypes.string,
  messages: PropTypes.objectOf(PropTypes.string),
  requireImageFunction: PropTypes.func,
  /** The component to be rendered when the href will be an internal path */
  routerLinkComponent: PropTypes.elementType,
  theme: PropTypes.objectOf(PropTypes.any),
};

NuDSProvider.propTypes = nuDSProviderShape;

NuDSProvider.defaultProps = {
  backgroundImageFunction: undefined,
  routerLinkComponent: undefined,
  theme: defaultTheme,
  colorScheme: defaultPrimaryColorScheme,
  formatMessage: () => {},
  requireImageFunction: requireImageFunctionDefault,
  locale: undefined,
  messages: {},
};

export default NuDSProvider;
