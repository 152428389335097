/* eslint-disable react/sort-prop-types */
import React, { forwardRef, useContext } from 'react';
import PropTypes from 'prop-types';

import colorVariantsShape from '../../styles/colors/colorVariantsShape';
import useIntlAriaAttrs from '../../utils/hooks/useIntlAriaAttrs';
import { NuDsContext } from '../NuDSProvider/NuDSProvider';
import { logDeprecatedFeature } from '../../utils/logger/logDeprecatedFeature/logDeprecatedFeature';
import { isLegacyColor } from '../../styles/themeUtils';

import { defaultStrong, TypographyWrapper } from './styles/TypographyWrapper';

const validateColorProps = ({ color, colorVariant }) => {
  let isValid = true;
  if (isLegacyColor(color) && color !== 'inherit') {
    logDeprecatedFeature('single color is deprecated, use use color=\'color.default\' instead');
    isValid = false;
  }

  if (colorVariant) {
    logDeprecatedFeature('colorVariant is deprecated, use color=\'color.variant\' instead');
    isValid = false;
  }

  return isValid;
};

export const variantTagMapping = {
  supertitle: 'h1',
  heading1: 'h1',
  heading2: 'h2',
  heading3: 'h3',
  heading4: 'h4',
  subtitle1: 'p',
  subtitle2: 'p',
  paragraph1: 'p',
  paragraph2: 'p',
  button: 'span',
  caption: 'span',
  overline: 'span',
};

export const typographyVariantsPropType = PropTypes.oneOf(Object.keys(variantTagMapping));

const Typography = forwardRef((props, ref) => {
  const {
    variant,
    tag,
    children,
    className,
    color,
    gutterBottom,
    intlKey,
    intlValues,
    colorVariant,
    strong,
    ...rest
  } = props;
  const { formatMessage } = useContext(NuDsContext);
  const intlAriaAttrs = useIntlAriaAttrs(props);
  const as = tag || variantTagMapping[variant];

  if (gutterBottom ?? false) {
    logDeprecatedFeature('gutterBottom is deprecated, use marginBottom instead of gutterBottom');
  }

  const hasLegacyColor = !validateColorProps({ color, colorVariant });

  return (
    <TypographyWrapper
      forwardedAs={as}
      className={className}
      color={color}
      colorVariant={colorVariant}
      gutterBottom={gutterBottom}
      variant={variant}
      hasLegacyColor={hasLegacyColor}
      strong={defaultStrong.includes(variant) ? true : strong}
      ref={ref}
      {...intlAriaAttrs}
      {...rest}
    >
      {intlKey
        ? formatMessage({ id: intlKey }, intlValues)
        : children}
    </TypographyWrapper>
  );
});

Typography.defaultProps = {
  strong: false,
  children: undefined,
  className: undefined,
  color: 'inherit',
  // TODO: Remove it after upgrade color usage in all websites
  colorVariant: undefined,
  gutterBottom: 0,
  lines: undefined,
  tag: undefined,
  variant: 'subtitle1',
  intlKey: null,
  intlValues: {},
  margin: undefined,
  marginTop: undefined,
  marginBottom: undefined,
  marginLeft: undefined,
  marginRight: undefined,
  marginHorizontal: undefined,
  marginVertical: undefined,
  padding: undefined,
  paddingTop: undefined,
  paddingBottom: undefined,
  paddingLeft: undefined,
  paddingRight: undefined,
  paddingHorizontal: undefined,
  paddingVertical: undefined,
  width: undefined,
  height: undefined,
  minWidth: undefined,
  maxWidth: undefined,
  minHeight: undefined,
  maxHeight: undefined,
  size: undefined,
  display: undefined,
  verticalAlign: undefined,
  overflow: undefined,
  overflowX: undefined,
  overflowY: undefined,
  textOverflow: undefined,
  wordBreak: undefined,
  textDecoration: undefined,
  whiteSpace: undefined,
};

export const typographyShape = {
  strong: PropTypes.bool,
  /** The content of the component, it can be either another component or raw text */
  children: PropTypes.node,
  /** The class that will be applied to the HTML element */
  className: PropTypes.string,
  /**
   * The color of the text;
   * This can be a NuDS color token or an browser specific value (initial, inherit)
  */
  color: PropTypes.string,
  /**
   * The variant of the color that you want to use;
   * The variants can be found on "Colors" section
   * */
  colorVariant: colorVariantsShape,
  /**
    Margin at the bottom of the component; It's computed in multiples of 16px
    (1 = 16px; 2 = 32px, etc.)
  */
  gutterBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  /** An i18n key that will be used to search for content */
  intlKey: PropTypes.string,
  /** An object containing the values to be passed down to the internationalized message */
  intlValues: PropTypes.shape({}),
  /** The number of lines that can be showed */
  lines: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  /** The actual HTML element to be rendered */
  tag: PropTypes.elementType,
  /** The font style that will be rendered */
  variant: typographyVariantsPropType,
  margin: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  marginTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  marginBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  marginLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  marginRight: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  marginHorizontal: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  marginVertical: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  padding: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  paddingTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  paddingBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  paddingLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  paddingRight: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  paddingHorizontal: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  paddingVertical: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  minHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  display: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  verticalAlign: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  overflow: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  overflowX: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  overflowY: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  textOverflow: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  wordBreak: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  textDecoration: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  whiteSpace: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

Typography.propTypes = typographyShape;

Typography.displayName = 'Typography';

export default Typography;
