import React, { useContext, forwardRef } from 'react';
import PropTypes from 'prop-types';

import { isType } from '../../utils/isType/isType';
import { NuDsContext } from '../NuDSProvider/NuDSProvider';

import { StyledTypography, UnstyledLink } from './styles/BaseLink';
import { StyledIcon } from './styles/StyledIcon';

export const variantMapper = {
  text: 'subtitle1',
  action: 'subtitle1',
};

export const colorMapper = {
  primary: {
    color: 'primary.default',
  },
  primaryLight: {
    color: 'primary.light',
  },
  white: {
    color: 'white.default',
  },
  black: {
    color: 'black.default',
  },
};

export const linkVariantsPropType = PropTypes.oneOf(Object.keys(variantMapper));
export const linkColorsPropType = PropTypes.oneOf([...Object.keys(colorMapper), 'inherit', 'initial']);

function renderActionIcons(variant, isExternal = true) {
  const canRender = variant === 'action';
  const iconName = isExternal ? 'arrow-up-right' : 'arrow-right';
  const titleName = isExternal ? 'External Link' : 'Internal Link';

  return canRender && (
    <StyledIcon size="small" title={titleName} name={iconName} />
  );
}

const GROUP_TO_PUT_SLASH = /\/?(\?|#|$)/;

function addSlashAtEnd(href) {
  return `${href.replace(GROUP_TO_PUT_SLASH, '/$1')}`;
}

const Link = forwardRef((props, ref) => {
  const {
    children,
    variant,
    color,
    href,
    iconProps,
    intlKey,
    intlValues,
    rel,
    target,
    typographyProps,
    noStyle,
    ...rest
  } = props;
  const { formatMessage, routerLinkComponent: RouterLinkComponent } = useContext(NuDsContext);
  const isExternalURL = !href.startsWith('/');
  const isAnchor = href.startsWith('#');
  const normalizedHref = !isAnchor && !isExternalURL ? addSlashAtEnd(href) : href;
  const typographyVariant = variantMapper[variant];
  const typographyColor = colorMapper[color];
  const componentChildren = intlKey
    ? formatMessage({ id: intlKey }, intlValues)
    : children;
  const LinkComponent = noStyle ? UnstyledLink : StyledTypography;

  const linkProps = {
    linkVariant: variant,
    variant: typographyVariant,
    strong: true,
    colorLink: color,
    ...typographyColor,
  };

  let attrsProps = {
    ...linkProps,
    ...typographyProps,
    noStyle,
    ref,
  };

  if (isType('undefined', target) && isExternalURL && !isAnchor) {
    const externalProps = {
      target: '_blank',
      rel: 'noopener',
    };

    attrsProps = { ...externalProps, ...attrsProps };
  }

  if (target) {
    if (target === '_blank') {
      attrsProps = { ...attrsProps, target, rel: 'noopener' };
    }
    attrsProps = { ...attrsProps, target };
  }

  if (rel) {
    attrsProps = { ...attrsProps, rel };
  }

  const iconComponent = Object.keys(iconProps).length
    ? (<StyledIcon size={iconProps.size} title={iconProps.title} name={iconProps.name} />)
    : renderActionIcons(variant, isExternalURL);

  const hasRouterLink = Boolean(RouterLinkComponent);

  if (!isExternalURL && hasRouterLink) {
    return (
      <RouterLinkComponent
        tag="a"
        href={normalizedHref}
        {...attrsProps}
        {...rest}
        linkComponent={LinkComponent}
      >
        {componentChildren}
        {iconComponent}
      </RouterLinkComponent>
    );
  }

  return (
    <LinkComponent
      tag="a"
      href={normalizedHref}
      {...attrsProps}
      {...rest}
    >
      {componentChildren}
      {iconComponent}
    </LinkComponent>
  );
});

Link.defaultProps = {
  children: undefined,
  className: null,
  color: 'primary',
  hrefLang: undefined,
  intlKey: null,
  intlValues: {},
  noStyle: false,
  variant: 'text',
  target: undefined,
  rel: undefined,
  typographyProps: {},
  iconProps: {},
};

Link.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  /** The color styles that will be rendered */
  color: linkColorsPropType,
  /** The URL that will be rendered */
  href: PropTypes.string.isRequired,
  /** Specifies the language of the linked document */
  hrefLang: PropTypes.string,
  /** An object containing the props to be passed down to the Icon component */
  iconProps: PropTypes.shape({
    name: PropTypes.string,
    size: PropTypes.string,
    title: PropTypes.string,
  }),
  /** An i18n key that will be used to search for content */
  intlKey: PropTypes.string,
  /** An object containing the values to be passed down to the internationalized message */
  intlValues: PropTypes.shape({}),
  /** Specifies if the styled component should be rendered */
  noStyle: PropTypes.bool,
  /** Specifies the relationship between the current document and the linked document */
  rel: PropTypes.oneOf([
    '',
    'alternate',
    'author',
    'bookmark',
    'external',
    'help',
    'license',
    'next',
    'nofollow',
    'noreferrer',
    'noopener',
    'prev',
    'search',
    'tag',
  ]),
  /** The target attribute specifies where to open the linked document */
  target: PropTypes.oneOf(['', '_blank', '_self', '_parent', '_parent', 'framename']),
  /** An object containing the props to be passed down to the Typography component */
  typographyProps: PropTypes.shape({
    color: PropTypes.string,
    colorVariant: PropTypes.string,
    variant: PropTypes.string,
  }),
  /** The font style that will be rendered */
  variant: linkVariantsPropType,
};

Link.displayName = 'Link';

export default Link;
