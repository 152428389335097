/* eslint-disable react/sort-prop-types */
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import BoxWrapper from './styles/BoxWrapper';

const Box = forwardRef((props, ref) => {
  const {
    children,
    id,
    className,
    tag,
    border,
    borderTop,
    borderTopColor,
    borderRight,
    borderRightColor,
    borderBottom,
    borderBottomColor,
    borderLeft,
    borderLeftColor,
    borderColor,
    borderWidth,
    borderStyle,
    borderRadius,
    background,
    backgroundColor,
    backgroundImage,
    backgroundSize,
    backgroundPosition,
    backgroundRepeat,
    color,
    display,
    width,
    height,
    maxWidth,
    maxHeight,
    minWidth,
    minHeight,
    size,
    verticalAlign,
    alignItems,
    alignContent,
    justifyContent,
    justifyItems,
    flexWrap,
    flexBasis,
    flexDirection,
    flex,
    justifySelf,
    alignSelf,
    order,
    gridGap,
    gridColumnGap,
    gridRowGap,
    gridColumn,
    gridRow,
    gridAutoFlow,
    gridAutoColumns,
    gridAutoRows,
    gridTemplateColumns,
    gridTemplateRows,
    gridTemplateAreas,
    gridArea,
    boxShadow,
    margin,
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
    marginHorizontal,
    marginVertical,
    padding,
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
    paddingHorizontal,
    paddingVertical,
    position,
    zIndex,
    top,
    right,
    bottom,
    left,
    transition,
    opacity,
    overflow,
    onClick,
    transform,
    transformOrigin,
    ...rest
  } = props;

  const boxProps = {
    id,
    className,
    forwardedAs: tag,
    border,
    borderTop,
    borderTopColor,
    borderRight,
    borderRightColor,
    borderBottom,
    borderBottomColor,
    borderLeft,
    borderLeftColor,
    borderColor,
    borderWidth,
    borderStyle,
    borderRadius,
    background,
    backgroundColor,
    backgroundImage,
    backgroundSize,
    backgroundPosition,
    backgroundRepeat,
    color,
    display,
    width,
    height,
    maxWidth,
    maxHeight,
    minWidth,
    minHeight,
    size,
    verticalAlign,
    alignItems,
    alignContent,
    justifyContent,
    justifyItems,
    flexWrap,
    flexBasis,
    flexDirection,
    flex,
    justifySelf,
    alignSelf,
    order,
    gridGap,
    gridColumnGap,
    gridRowGap,
    gridColumn,
    gridRow,
    gridAutoFlow,
    gridAutoColumns,
    gridAutoRows,
    gridTemplateColumns,
    gridTemplateRows,
    gridTemplateAreas,
    gridArea,
    boxShadow,
    margin,
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
    marginHorizontal,
    marginVertical,
    padding,
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
    paddingHorizontal,
    paddingVertical,
    position,
    zIndex,
    top,
    right,
    bottom,
    left,
    transition,
    opacity,
    overflow,
    onClick,
    transform,
    transformOrigin,
    ...rest,
  };

  return (
    <BoxWrapper {...boxProps} ref={ref}>
      {children}
    </BoxWrapper>
  );
});

Box.defaultProps = {
  tag: undefined,
  id: undefined,
  className: undefined,
  children: null,
  display: undefined,
  background: undefined,
  backgroundColor: undefined,
  backgroundImage: undefined,
  backgroundSize: undefined,
  backgroundPosition: undefined,
  backgroundRepeat: undefined,
  border: undefined,
  borderTop: undefined,
  borderTopColor: undefined,
  borderRight: undefined,
  borderRightColor: undefined,
  borderBottom: undefined,
  borderBottomColor: undefined,
  borderLeft: undefined,
  borderLeftColor: undefined,
  borderColor: undefined,
  borderWidth: undefined,
  borderStyle: undefined,
  borderRadius: undefined,
  color: undefined,
  width: undefined,
  height: undefined,
  maxWidth: undefined,
  maxHeight: undefined,
  minWidth: undefined,
  minHeight: undefined,
  size: undefined,
  verticalAlign: undefined,
  boxShadow: undefined,
  alignItems: undefined,
  alignContent: undefined,
  justifyContent: undefined,
  justifyItems: undefined,
  flexWrap: undefined,
  flexBasis: undefined,
  flexDirection: undefined,
  flex: undefined,
  justifySelf: undefined,
  alignSelf: undefined,
  order: undefined,
  gridGap: undefined,
  gridColumnGap: undefined,
  gridRowGap: undefined,
  gridColumn: undefined,
  gridRow: undefined,
  gridAutoFlow: undefined,
  gridAutoColumns: undefined,
  gridAutoRows: undefined,
  gridTemplateColumns: undefined,
  gridTemplateRows: undefined,
  gridTemplateAreas: undefined,
  gridArea: undefined,
  margin: undefined,
  marginTop: undefined,
  marginRight: undefined,
  marginBottom: undefined,
  marginLeft: undefined,
  marginHorizontal: undefined,
  marginVertical: undefined,
  padding: undefined,
  paddingTop: undefined,
  paddingRight: undefined,
  paddingBottom: undefined,
  paddingLeft: undefined,
  paddingHorizontal: undefined,
  paddingVertical: undefined,
  position: undefined,
  zIndex: undefined,
  top: undefined,
  right: undefined,
  bottom: undefined,
  left: undefined,
  transition: undefined,
  transform: undefined,
  transformOrigin: undefined,
  opacity: undefined,
  overflow: undefined,
  onClick: undefined,
};

Box.propTypes = {
  /** The actual HTML element to be rendered */
  tag: PropTypes.elementType,
  /** The id that will be applied to the HTML element */
  id: PropTypes.string,
  /** The class that will be applied to the HTML element */
  className: PropTypes.string,
  /** The content of the component, it can be either another component or raw text */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  display: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  background: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  backgroundColor: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  backgroundImage: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  backgroundSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  backgroundPosition: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  backgroundRepeat: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  border: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  borderTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  borderTopColor: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  borderRight: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  borderRightColor: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  borderBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  borderBottomColor: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  borderLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  borderLeftColor: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  borderColor: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  borderWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  borderStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  alignItems: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  alignContent: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  justifyContent: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  justifyItems: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  flexWrap: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  flexBasis: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  flexDirection: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  flex: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  justifySelf: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  alignSelf: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  order: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  minHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  verticalAlign: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  boxShadow: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  gridGap: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  gridColumnGap: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  gridRowGap: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  gridColumn: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  gridRow: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  gridAutoFlow: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  gridAutoColumns: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  gridAutoRows: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  gridTemplateColumns: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  gridTemplateRows: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  gridTemplateAreas: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  gridArea: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  margin: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  marginTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  marginRight: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  marginBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  marginLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  marginHorizontal: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  marginVertical: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  padding: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  paddingTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  paddingRight: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  paddingBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  paddingLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  paddingHorizontal: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  paddingVertical: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  position: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  zIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  top: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  right: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  bottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  left: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  transition: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  transform: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  transformOrigin: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  opacity: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  overflow: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  onClick: PropTypes.func,
};

Box.displayName = 'Box';

export default Box;
