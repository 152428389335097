import styled from 'styled-components';

import { nuDSColor } from '../../../styles/themeUtils';

import { sectionStyle } from './commonFooterStyles';

export const FooterWrapper = styled.footer`
  background-color: ${nuDSColor('black')};
`;

FooterWrapper.displayName = 'FooterWrapper';

export const Section = styled.div`
  ${sectionStyle};
`;

Section.displayName = 'Section';
