import styled from 'styled-components';

import { desktop1024 } from '../../../../../styles/breakpoints';
import { sectionStyle } from '../../../styles/commonFooterStyles';

export const CompanyInfoContainer = styled.div`
  ${desktop1024`
    margin-top: 0;
  `}
`;

CompanyInfoContainer.displayName = 'CompanyInfoContainer';

export const CompanyContainer = styled.div`
  ${sectionStyle};

  display: grid;
  grid-row-gap: 40px;

  ${desktop1024`
    grid-template-columns: 98px 9fr 2fr;
  `}
`;

CompanyContainer.displayName = 'CompanyContainer';
