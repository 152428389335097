import PropTypes from 'prop-types';
import styled from '@xstyled/styled-components';
import React from 'react';
import {
  compose,
  createSystemComponent,
  createStyleGenerator,
  reduceBreakpoints,
  backgrounds,
  order,
  style,
  getSpace,
  getPercent,
} from '@xstyled/system';
import { obj, merge } from '@xstyled/util';

const direction = style({
  prop: 'direction',
  cssProperty: 'flexDirection',
});

const wrap = style({
  prop: 'wrap',
  cssProperty: 'flexWrap',
});

const alignItems = style({
  prop: 'alignItems',
});

const alignSelf = style({
  prop: 'alignSelf',
});

const justify = style({
  prop: 'justify',
  cssProperty: 'justifyContent',
});

const spacingTop = style({
  prop: 'spacingTop',
  cssProperty: 'paddingTop',
  themeGet: getSpace,
});

const spacingBottom = style({
  prop: 'spacingBottom',
  cssProperty: 'paddingBottom',
  themeGet: getSpace,
});

const spacingRight = style({
  prop: 'spacingRight',
  cssProperty: 'paddingRight',
  themeGet: getSpace,
});

const spacingLeft = style({
  prop: 'spacingLeft',
  cssProperty: 'paddingLeft',
  themeGet: getSpace,
});

export const spacingHorizontal = style({
  prop: 'spacingHorizontal',
  cssProperty: ['paddingRight', 'paddingLeft'],
  themeGet: getSpace,
});

export const spacingVertical = style({
  prop: 'spacingVertical',
  cssProperty: ['paddingTop', 'paddingBottom'],
  themeGet: getSpace,
});

function getColGuttersStyle(props, gutters) {
  if (!props.col) return false;

  if (obj(gutters)) {
    const breakpointsStyle = reduceBreakpoints(
      props,
      gutters,
      breakpointValue => ({
        paddingRight: `${getSpace(breakpointValue)(props)}`,
        paddingLeft: `${getSpace(breakpointValue)(props)}`,
      }),
    );

    return {
      ...breakpointsStyle,
    };
  }

  return {
    paddingRight: `${getSpace(gutters)(props)}`,
    paddingLeft: `${getSpace(gutters)(props)}`,
  };
}

function getRowGuttersStyle(props, gutters) {
  if (!props.row) return false;

  if (obj(gutters)) {
    const breakpointsStyle = reduceBreakpoints(
      props,
      gutters,
      breakpointValue => ({
        paddingTop: `${getSpace(breakpointValue)(props)}`,
        paddingBottom: `${getSpace(breakpointValue)(props)}`,
      }),
    );

    return {
      ...breakpointsStyle,
    };
  }

  return {
    paddingTop: `${getSpace(gutters)(props)}`,
    paddingBottom: `${getSpace(gutters)(props)}`,
  };
}

function getColStyle(props, size) {
  if (size === 'fill') {
    return {
      flexBasis: 0,
      flexGrow: 1,
      maxWidth: '100%',
    };
  }

  if (size === 'auto') {
    return {
      flex: '0 0 auto',
      maxWidth: 'none',
      width: 'auto',
    };
  }

  const sizeWidth = getPercent(size / 12)(props);

  return {
    flex: `0 0 ${sizeWidth}`,
    maxWidth: sizeWidth,
  };
}

const getOffsetStyle = (props, size) => {
  if (size === 'auto') {
    return {
      marginLeft: 'auto',
    };
  }

  const sizeWidth = getPercent(size / 12)(props);

  return {
    marginLeft: sizeWidth,
  };
};

const componentBaseStyles = {
  row: props => {
    const guttersValue = props.gutters;
    const common = {
      boxSizing: 'border-box',
      flexGrow: 1,
      flexWrap: 'wrap',
      display: 'flex',
    };

    return {
      ...common,
      ...getRowGuttersStyle(props, guttersValue),
    };
  },
  col: props => {
    const colValue = props.col;
    const guttersValue = props.gutters;
    const common = {
      boxSizing: 'border-box',
      flexBasis: 0,
      flexGrow: 1,
      maxWidth: '100%',
    };

    if (obj(colValue)) {
      const breakpointsStyleCol = reduceBreakpoints(
        props,
        colValue,
        breakpointValue => getColStyle(props, breakpointValue),
      );

      const breakpointsStyleGutters = getColGuttersStyle(props, guttersValue);
      const breakpointsStyle = merge(breakpointsStyleCol, breakpointsStyleGutters);

      return {
        ...common,
        ...breakpointsStyle,
      };
    }

    return {
      ...common,
      ...getColStyle(props, colValue),
      ...getColGuttersStyle(props, guttersValue),
    };
  },
  offset: props => {
    const offsetvalue = props.offset;

    if (obj(offsetvalue)) {
      const breakpointsStyle = reduceBreakpoints(
        props,
        offsetvalue,
        breakpointValue => getOffsetStyle(props, breakpointValue),
      );
      return {
        ...breakpointsStyle,
      };
    }

    return {
      ...getOffsetStyle(props, offsetvalue),
    };
  },
};

const createStyleProps = componentName => createStyleGenerator(
  props => ({
    ...(props[componentName] && componentBaseStyles[componentName](props)),
  }),
  [componentName],
);

const row = createStyleProps('row');
const col = createStyleProps('col');
const offset = createStyleProps('offset');

export const styleProps = compose(
  col,
  row,
  wrap,
  alignItems,
  alignSelf,
  justify,
  direction,
  order,
  spacingTop,
  spacingBottom,
  spacingRight,
  spacingLeft,
  spacingHorizontal,
  spacingVertical,
  offset,
  backgrounds,
);

const InnerGrid = createSystemComponent(React, 'div', styleProps);

const GridWrapper = styled(InnerGrid).withConfig({
  shouldForwardProp: (prop, validate) => validate(prop) && !styleProps.meta.props.includes(prop),
})`
  ${styleProps};
`;

function getSystemPropTypes(system) {
  if (!system) return {};
  return system.meta.props.reduce((result, prop) => ({
    ...result,
    [prop]: PropTypes.oneOfType([
      PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.bool,
    ]),
  }), {});
}

GridWrapper.displayName = 'GridWrapper';
GridWrapper.propTypes = getSystemPropTypes(styleProps);

export default GridWrapper;
