import React from 'react';
import PropTypes from 'prop-types';

import Box from '../../../Box/Box';
import Icon from '../../../Icon/Icon';
import IconStructure from '../../../Icon/components/IconStructure/IconStructure';

import { Header } from './styles/Header';

export function CollapseHeader({
  children,
  iconSize,
  isOpen,
  id,
  handleToggleButton,
  iconToClose,
  iconToOpen,
}) {
  const buttonIcon = isOpen ? iconToClose : iconToOpen;

  return (
    <Header
      id={id}
      type="button"
      onClick={handleToggleButton}
      aria-controls={`${id}-body`}
      aria-expanded={isOpen}
    >
      {children}
      <Box marginLeft="3x">
        <Icon
          name={buttonIcon}
          size={iconSize}
          colorVariant="black"
        />
      </Box>
    </Header>
  );
}

CollapseHeader.defaultProps = {
  id: undefined,
  isOpen: false,
  handleToggleButton: () => {},
  iconSize: 'small',
  iconToClose: 'minus',
  iconToOpen: 'plus',
  children: '',
};

CollapseHeader.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  handleToggleButton: PropTypes.func,
  iconSize: IconStructure.propTypes.size,
  iconToClose: PropTypes.string,
  iconToOpen: PropTypes.string,
  id: PropTypes.string,
  isOpen: PropTypes.bool,
};
