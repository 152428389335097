import styled from 'styled-components';

import { nuDSColor } from '../../../../../styles/themeUtils';
import Link from '../../../../../components/Link/Link';

const ColumnLink = styled(Link)`
  margin-bottom: 0.5rem;
  color: ${nuDSColor('white')};
  &:hover {
    text-decoration: none;
    background: none;
    color: ${nuDSColor('primary', 'light')};
  }
  &:focus {
    text-decoration: none;
    background: none;
  }
`;

ColumnLink.defaultProps = {
  typographyProps: {
    variant: 'subtitle1',
  },
};

ColumnLink.displayName = 'ColumnLink';

export default ColumnLink;
