import React from 'react';
import PropTypes from 'prop-types';

import GridWrapper from './styles/GridWrapper';

function Grid({
  children,
  tag,
  row,
  col,
  offset,
  spacing,
  spacingBottom,
  spacingHorizontal,
  spacingLeft,
  spacingRight,
  spacingTop,
  spacingVertical,
  ...rest
}) {
  const gridProps = {
    forwardedAs: tag,
    row,
    col,
    offset,
    spacingBottom,
    spacingHorizontal,
    spacingLeft,
    spacingRight,
    spacingTop,
    spacingVertical,
    gutters: ['0x', 0].includes(spacing) ? {} : spacing,
    ...rest,
  };

  return (
    <GridWrapper {...gridProps}>
      {React.Children.map(children, child => {
        if (child.props && child.props.col) {
          return React.cloneElement(child, {
            spacing,
          });
        }
        return child;
      })}
    </GridWrapper>
  );
}

Grid.defaultProps = {
  tag: undefined,
  className: undefined,
  children: null,
  row: undefined,
  col: undefined,
  spacing: {
    xs: '2x',
    md: '4x',
  },
  spacingBottom: undefined,
  spacingHorizontal: undefined,
  spacingLeft: undefined,
  spacingRight: undefined,
  spacingTop: undefined,
  spacingVertical: undefined,
  offset: undefined,
  direction: undefined,
  justify: undefined,
  alignItems: undefined,
  alignSelf: undefined,
};

Grid.propTypes = {
  /** Use alignItems in Row to set the vertical alignment  */
  alignItems: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  /** Use alignSelf in Col to set the vertical alignment  */
  alignSelf: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  /** The content of the component, it can be either another component or raw text */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  /** The class that will be applied to the HTML element */
  className: PropTypes.string,
  /** Cols based on a 12-column grid layout */
  col: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  /** Use direction in Row to set directions cols */
  direction: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  /** Use justify in Row to set the horizontal alignment  */
  justify: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  /** Use offset to move columns to the right */
  offset: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  /** Rows are wrappers for columns. */
  row: PropTypes.oneOfType([PropTypes.bool]),
  /** The responsive grid focuses on consistent spacing widths, rather than column width */
  spacing: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  /** Use spacingBottom in Row or Col to set the spacing top  */
  spacingBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  /** Use spacingHorizontal in Row or Col to set the spacing top  */
  spacingHorizontal: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  /** Use spacingleft in Row or Col to set the spacing top  */
  spacingLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  /** Use spacingRight in Row or Col to set the spacing top  */
  spacingRight: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  /** Use spacingTop in Row or Col to set the spacing top  */
  spacingTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  /** Use spacingVertical in Row or Col to set the spacing top  */
  spacingVertical: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  /** The actual HTML element to be rendered */
  tag: PropTypes.elementType,
};

export default Grid;
