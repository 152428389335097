import styled from '@xstyled/styled-components';
import { getSystemPropTypes } from '@xstyled/prop-types';
import React from 'react';
import {
  compose,
  width,
  height,
  maxWidth,
  maxHeight,
  minWidth,
  minHeight,
  size,
  verticalAlign,
  color,
  backgroundColor,
  backgroundPosition,
  backgroundSize,
  backgroundRepeat,
  background,
  borders,
  flexboxes,
  grids,
  opacity,
  overflow,
  transition,
  positioning,
  boxShadow,
  margin,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
  padding,
  paddingTop,
  paddingRight,
  paddingBottom,
  paddingLeft,
  createSystemComponent,
  style,
  getSpace,
  themeGetter,
} from '@xstyled/system';

import { backgroundImage as backgroundImageFunction } from '../../Image/Image';

const getTransform = themeGetter({
  name: 'transform',
  key: 'transforms',
});

const transform = style({
  prop: 'transform',
  themeGet: getTransform,
  cssProperty: 'transform',
});

const transformOrigin = style({
  prop: 'transformOrigin',
});

const marginHorizontal = style({
  prop: 'marginHorizontal',
  cssProperty: ['marginRight', 'marginLeft'],
  themeGet: getSpace,
});

const marginVertical = style({
  prop: 'marginVertical',
  cssProperty: ['marginTop', 'marginBottom'],
  themeGet: getSpace,
});

const paddingHorizontal = style({
  prop: 'paddingHorizontal',
  cssProperty: ['paddingRight', 'paddingLeft'],
  themeGet: getSpace,
});

const paddingVertical = style({
  prop: 'paddingVertical',
  cssProperty: ['paddingTop', 'paddingBottom'],
  themeGet: getSpace,
});

export const styleProps = compose(
  backgroundColor,
  backgroundPosition,
  backgroundSize,
  backgroundRepeat,
  background,
  color,
  borders,
  flexboxes,
  grids,
  width,
  height,
  maxWidth,
  maxHeight,
  minWidth,
  minHeight,
  size,
  verticalAlign,
  opacity,
  overflow,
  transition,
  positioning,
  boxShadow,
  margin,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
  marginHorizontal,
  marginVertical,
  padding,
  paddingTop,
  paddingRight,
  paddingBottom,
  paddingLeft,
  paddingHorizontal,
  paddingVertical,
  transform,
  transformOrigin,
);

const InnerBox = createSystemComponent(React, 'div', styleProps);
const BoxWrapper = styled(InnerBox).withConfig({
  shouldForwardProp: (prop, validate) => validate(prop) && !styleProps.meta.props.includes(prop),
})`
  ${styleProps};
  ${({ backgroundImage: backgroundImageProp }) => backgroundImageProp && backgroundImageFunction(backgroundImageProp)} 
`;

BoxWrapper.displayName = 'BoxWrapper';
BoxWrapper.propTypes = getSystemPropTypes(styleProps);

export default BoxWrapper;
