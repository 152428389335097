import React from 'react';

import Typography from '../../../../components/Typography/Typography';

export default function ColumnTitle(props) {
  return (
    <Typography
      color="white.dark"
      gutterBottom={2}
      variant="subtitle1"
      {...props}
    />
  );
}
