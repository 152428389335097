/* eslint-disable react/no-danger */
import { createGlobalStyle } from '@xstyled/styled-components';

export function createFontFaceSRC({ url, format }) {
  return `url('${url}') format('${format}')`;
}

export function joinFontFaceSRC(sources = []) {
  return sources
    .map(createFontFaceSRC)
    .join(',');
}

export function createFontFaces({ typography }) {
  return typography.map(({ fontFamily, sources }) => `
      @font-face {
        font-family: ${fontFamily};
        src: ${joinFontFaceSRC(sources)};
        font-style: normal;
        font-display: swap;
      }
    `).join('');
}

export const GlobalFonts = createGlobalStyle`
  ${props => (createFontFaces(props.theme))};
`;

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -webkit-appearance: none; /* stylelint-disable-line property-no-vendor-prefix */
    text-size-adjust: 100%;
    border-color: initial;
    border-image: initial;
    border-style: initial;
    border-width: 0;
    font-weight: inherit;
    outline: 0;
    padding: 0;
    text-rendering: optimizeLegibility;
  }

  body {
    font-family: 'Graphik-Regular','Gotham SSm A','Gotham SSm B','Helvetica Neue',Helvetica,Arial,sans-serif !important;
  }

  ol, ul {
    list-style: none;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  /* Input reset CSS */
  /* Firefox */
  input:invalid {
    box-shadow: none;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border-color: var(--inputCurrentColor);
    -webkit-text-fill-color: var(--inputCurrentColor);
    -webkit-box-shadow: 0 0 0px 1000px white inset !important; /* stylelint-disable-line property-no-vendor-prefix */
  }

  input[type='number'] {
    appearance: none;
  }

  input[type='tel'] {
    appearance: textfield;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
  }
`;

export default GlobalStyle;
