import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';

import { NuDsContext } from '../NuDSProvider/NuDSProvider';

import prepareSourcesToRender, { getSmallerSourcePathFrom } from './prepareSourcesToRender';
import imageSourceResolver from './imageSourceResolver';
import ImageWrapper from './styles/ImageWrapper';

export { default as backgroundImage } from './backgroundImage/backgroundImage';

function srcSetReducerFor(imageType, requireImageFn) {
  return (acc, source, index, arr) => {
    const isLastItem = arr.length - 1 === index;
    return `${acc}${imageSourceResolver(source.path, { type: imageType, requireImage: requireImageFn })} ${source.pixelDensity}${!isLastItem ? ',' : ''}`;
  };
}
function Image({
  alt,
  altIntlKey,
  className,
  loading,
  importance,
  src,
  srcSet,
  webp,
  title,
  titleIntlKey,
  type,
  width,
  height,
  maxWidth,
  objectFit,
  objectPosition,
}) {
  const { formatMessage, requireImageFunction } = useContext(NuDsContext);
  const altValue = alt || formatMessage({ id: altIntlKey });
  const hasTitle = title || titleIntlKey;

  const titleValue = hasTitle
    ? title || formatMessage({ id: titleIntlKey })
    : null;

  const sourcesToRenderByBreakpoint = prepareSourcesToRender(srcSet);
  const baseFileSourcePath = src || getSmallerSourcePathFrom(sourcesToRenderByBreakpoint);
  const hasType = Boolean(type);
  const isWebpType = (webp || type === 'webp');
  const isTypeEnabled = isWebpType || hasType;
  const imageType = isWebpType ? 'webp' : type;
  const imageTypeAttr = isTypeEnabled && { type: `image/${imageType}` };

  return (
    <picture>
      {sourcesToRenderByBreakpoint.map(({
        breakPointValue, source, sources,
      }) => (
        <Fragment key={breakPointValue}>
          {isTypeEnabled && (
            <source
              media={`(min-width:${breakPointValue}px)`}
              {...imageTypeAttr}
              srcSet={source
                ? imageSourceResolver(source.path, {
                  type: imageType, requireImage: requireImageFunction,
                })
                : sources.reduce(srcSetReducerFor(imageType, requireImageFunction), '')}
            />
          )}
          <source
            media={`(min-width:${breakPointValue}px)`}
            srcSet={source
              ? imageSourceResolver(source.path, {
                type: '', requireImage: requireImageFunction,
              })
              : sources.reduce(srcSetReducerFor('', requireImageFunction), '')}
          />
        </Fragment>
      ))}

      {isTypeEnabled && (
        <source
          {...imageTypeAttr}
          srcSet={imageSourceResolver(baseFileSourcePath, {
            imageType, requireImage: requireImageFunction,
          })}
        />
      )}
      <ImageWrapper
        src={imageSourceResolver(baseFileSourcePath, { requireImage: requireImageFunction })}
        alt={altValue}
        className={className}
        loading={loading}
        importance={importance}
        title={titleValue}
        width={width}
        height={height}
        maxWidth={maxWidth}
        objectFit={objectFit}
        objectPosition={objectPosition}
      />
    </picture>
  );
}

Image.defaultProps = {
  alt: '',
  altIntlKey: '',
  className: '',
  loading: 'auto',
  importance: 'auto',
  webp: false,
  srcSet: {},
  src: '',
  title: '',
  titleIntlKey: '',
  type: '',
  width: '100%',
  height: 'auto',
  maxWidth: '100%',
  objectFit: undefined,
  objectPosition: '',
};

const DPRPropTypes = PropTypes.shape({
  '1x': PropTypes.string.isRequired,
  '2x': PropTypes.string,
  '3x': PropTypes.string,
});

export const imageShape = {
  alt: PropTypes.string,
  altIntlKey: PropTypes.string,
  className: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  importance: PropTypes.oneOf(['high', 'low', 'auto']),
  loading: PropTypes.oneOf(['lazy', 'eager', 'auto']),
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  objectFit: PropTypes.oneOfType([PropTypes.oneOf(['fill', 'contain', 'cover', 'none', 'scale-down']), PropTypes.object]),
  objectPosition: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  srcSet: PropTypes.shape({
    /* eslint-disable react/sort-prop-types */
    xl: PropTypes.oneOfType([PropTypes.string, DPRPropTypes]),
    lg: PropTypes.oneOfType([PropTypes.string, DPRPropTypes]),
    md: PropTypes.oneOfType([PropTypes.string, DPRPropTypes]),
    sm: PropTypes.oneOfType([PropTypes.string, DPRPropTypes]),
    xs: PropTypes.oneOfType([PropTypes.string, DPRPropTypes]),
    /* eslint-disable react/sort-prop-types */
  }),
  src: PropTypes.string,
  title: PropTypes.string,
  titleIntlKey: PropTypes.string,
  type: PropTypes.string,
  webp: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
};

Image.propTypes = imageShape;

export default React.memo(Image);
