import { mix, transparentize } from 'polished';

export const colorVariants = [
  'default',
  'defaultT10',
  'defaultT20',
  'defaultT70',
  'defaultL10',
  'defaultL20',
  'defaultL40',
  'dark',
  'light',
];

const lightenAlpha = {
  10: 0.1,
  20: 0.2,
  40: 0.4,
  70: 0.7,
};

const transparencyAlpha = {
  10: 0.9,
  20: 0.8,
  40: 0.6,
  70: 0.3,
};

export function lighten(color, transparency) {
  return mix(transparency, color, '#FFFFFF').toUpperCase();
}

export function colorBuilder(
  base,
  baseDarkLightVariations,
  variations = {
    includeDefaultShades: false,
    includeLightShades: false,
    includeDefaultTransparency: false,
  },
) {
  const { dark, light } = baseDarkLightVariations;

  return {
    default: base,
    ...(light && {
      light,
    }),
    ...(dark && {
      dark,
    }),
    ...(variations.includeDefaultShades && {
      defaultL40: lighten(base, lightenAlpha[40]),
      defaultL20: lighten(base, lightenAlpha[20]),
      defaultL10: lighten(base, lightenAlpha[10]),
    }),
    // TODO: Align how we will deal with this changes
    ...(variations.includeLightShades && {
      defaultL40: lighten(light, lightenAlpha[40]),
      defaultL20: lighten(light, lightenAlpha[20]),
      defaultL10: lighten(light, lightenAlpha[10]),
    }),
    ...(variations.includeDefaultTransparency && {
      defaultT70: transparentize(transparencyAlpha[70], base),
      defaultT20: transparentize(transparencyAlpha[20], base),
      defaultT10: transparentize(transparencyAlpha[10], base),
    }),
  };
}
