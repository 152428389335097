import { DEFAULT_BREAKPOINTS as breakpoints } from './breakpoints';
import { DEFAULT_SPACE as space } from './spacing/spacing';
import colors from './colors/colors';

export const zIndexes = {
  jumpToContent: 9999,
};

export const gutter = {
  mobileSmall: 16,
  mobile: 24,
  tablet: 24,
  desktop: 32,
  desktopLarge: 32,
};

export const margin = {
  mobileSmall: 24,
  mobile: 24,
  tablet: 40,
  desktop: 56,
  desktopLarge: 56,
};

export const typography = [
  {
    fontFamily: 'Graphik-Regular',
    sources: [
      {
        url: 'https://cdn.nubank.com.br/fonts/Graphik/variants/Graphik-Regular.woff2',
        format: 'woff2',
      },
      {
        url: 'https://cdn.nubank.com.br/fonts/Graphik/variants/Graphik-Regular.woff',
        format: 'woff',
      },
      {
        url: 'https://cdn.nubank.com.br/fonts/Graphik/variants/Graphik-Regular.ttf',
        format: 'truetype',
      },
      {
        url: 'https://cdn.nubank.com.br/fonts/Graphik/variants/Graphik-Regular.svg#Graphik-Regular',
        format: 'svg',
      },
      {
        url: 'https://cdn.nubank.com.br/fonts/Graphik/variants/Graphik-Regular.eot?#iefix',
        format: 'embedded-opentype',
      },
    ],
  },
  {
    fontFamily: 'Graphik-Medium',
    sources: [
      {
        url: 'https://cdn.nubank.com.br/fonts/Graphik/variants/Graphik-Medium.woff2',
        format: 'woff2',
      },
      {
        url: 'https://cdn.nubank.com.br/fonts/Graphik/variants/Graphik-Medium.woff',
        format: 'woff',
      },
    ],
  },
];

export default {
  baseSpacingValue: 4,
  colors,
  typography,
  gutter,
  margin,
  zIndexes,
  breakpoints,
  space,
};
