import React from 'react';
import PropTypes from 'prop-types';

import { CompanyContainer } from './styles/CompanyWrappers';
import { Social } from './styles/Social';
import CompanyInfo from './CompanyInfo';

function Company({ children, className, ...others }) {
  return (
    <CompanyContainer className={className} {...others}>
      {children({
        CompanyInfo,
        Social,
      })}
    </CompanyContainer>
  );
}

Company.defaultProps = {
  className: undefined,
};

Company.propTypes = {
  children: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default Company;
