import { colorBuilder } from './colorBuilder';

export const baseColors = {
  violetDark: '#820AD1',
  violetHeliotrope: '#AB4BEA',
  violetIndigo: '#4C0677',
  violetMauve: '#9C63C0',
  black: '#111111',
  grayNero: '#191919',
  grayWhiteSmoke: '#F5F5F5',
  white: '#FFFFFF',
  greenDarkSpring: '#1F7F45',
  greenJungle: '#2DB965',
  redAlizarin: '#D72923',
  redGeraldine: '#E9807C',
  orangeTenne: '#EF6C00',
  orangeNeonCarrot: '#FF943D',
  blueSurfie: '#00737B',
  blueIris: '#00BBC8',
};

const brand = {
  default: baseColors.violetDark,
  light: baseColors.violetHeliotrope,
  defaultL40: '#CF8CFC',
  defaultL20: '#E0C2FF',
  defaultL10: '#F4E7FF',
};

export const products = {
  conta: brand,
  creditCard: brand,
  rewards: brand,
  pj: {
    default: baseColors.violetIndigo,
    light: baseColors.violetMauve,
    defaultL40: '#C39BDC',
    defaultL20: '#E1CAF0',
    defaultL10: '#F4EAFB',
  },
};

export const grayscale = {
  black: colorBuilder(baseColors.black, { light: baseColors.grayNero }, {
    includeDefaultTransparency: true,
  }),
  white: colorBuilder(baseColors.white, { dark: baseColors.grayWhiteSmoke }, {
    includeDefaultTransparency: true,
  }),
};

export const utility = {
  success: colorBuilder(baseColors.greenDarkSpring, { light: baseColors.greenJungle }),
  error: colorBuilder(baseColors.redAlizarin, { light: baseColors.redGeraldine }),
  warning: colorBuilder(baseColors.orangeTenne, { light: baseColors.orangeNeonCarrot }),
  info: colorBuilder(baseColors.blueSurfie, { light: baseColors.blueIris }),
};

const colors = {
  brand,
  primary: brand,
  ...utility,
  ...products,
  ...grayscale,
};

export default colors;
