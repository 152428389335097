import { useContext } from 'react';
import { css } from '@xstyled/styled-components';

import { isType } from '../../../utils/isType/isType';
import { breakpointsMedia } from '../../../styles/breakpoints';
import { NuDsContext } from '../../NuDSProvider/NuDSProvider';

const handleBackground = imagePath => {
  const { requireImageFunction } = useContext(NuDsContext);

  const isImagePathNone = imagePath === 'none';
  if (isImagePathNone) {
    return css`
      background-image: none;
    `;
  }

  return css`
    background-image: url('${requireImageFunction(imagePath)}');
  `;
};

function createDPR(dpr) {
  return {
    pixelRatio: dpr,
    dpi: 96 * dpr,
    ddpx: dpr,
  };
}

const DPRValuesByDensity = {
  '1x': createDPR(1),
  '2x': createDPR(2),
  '3x': createDPR(2.5),
};

function handlePixelRatioBreakpoints(srcSetDPR) {
  const has1xImage = srcSetDPR['1x'];
  if (!has1xImage) {
    throw new Error('You must provide the "1x" image to be used as default');
  }

  const breakpointDPRs = Object.entries(srcSetDPR);

  return css`
    ${breakpointDPRs.map(([currentDPR, currentImagePath]) => {
    const currentDensityDPRValues = DPRValuesByDensity[currentDPR];

    if (currentDPR === '1x') {
      return css`
        ${handleBackground(currentImagePath)}
      `;
    }

    return css`
      @media
      only screen and (-webkit-min-device-pixel-ratio: ${currentDensityDPRValues.pixelRatio}),
      only screen and (        min-device-pixel-ratio: ${currentDensityDPRValues.pixelRatio}),
      only screen and (                min-resolution: ${currentDensityDPRValues.dpi}dpi),
      only screen and (                min-resolution: ${currentDensityDPRValues.ddpx}dppx) {
        ${handleBackground(currentImagePath)}
      }
    `;
  })}
  `;
}

function handleBackgroundWithDPR(srcSetItem) {
  const isPixelRatioBreakpointPath = !isType('string', srcSetItem);
  return isPixelRatioBreakpointPath
    ? handlePixelRatioBreakpoints(srcSetItem)
    : handleBackground(srcSetItem);
}

const backgroundImage = (srcSet, ...args) => () => {
  const { backgroundImageFunction } = useContext(NuDsContext);
  const hasBackgroundImageFunction = Boolean(backgroundImageFunction);

  if (hasBackgroundImageFunction) {
    return backgroundImageFunction(srcSet, ...args);
  }

  if (isType('string', srcSet)) {
    return handleBackground(srcSet);
  }

  return breakpointsMedia(Object.fromEntries(
    Object.entries(srcSet)
      .map(([key, set]) => ([key, css`
        ${handleBackgroundWithDPR(set)}
      `])),
  ));
};

export default backgroundImage;
