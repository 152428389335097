import PropTypes from 'prop-types';
import styled, { keyframes } from '@xstyled/styled-components';

const waveRippleAnimation = keyframes`
  0% {
    transform: scale(0);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: scale(var(--scale));
  }
`;

export const WaveBase = styled.div`
  --scale: ${({ scale }) => scale};
  width: .3125rem;
  height: .3125rem;
  position: absolute;
  top: calc(${({ top }) => top}px - .3125rem);
  left: calc(${({ left }) => left}px - .3125rem);
  border-radius: inherit;

  &:after {
    content: "";
    display: block;
    border-radius: inherit;
    width: .625rem;
    height: .625rem;
    background: var(--rippleLayerColor);

    will-change: transform, opacity;
    transform: scale(0);
    opacity: 0;
    animation: ${waveRippleAnimation} 500ms;
  }
`;

WaveBase.displayName = 'Wave';

WaveBase.propTypes = {
  left: PropTypes.number.isRequired,
  scale: PropTypes.number.isRequired,
  top: PropTypes.number.isRequired,
};
