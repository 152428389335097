import React from 'react';
import PropTypes from 'prop-types';

import Box from '../../../Box/Box';

export function CollapseBody({
  children,
  isOpen,
  id,
}) {
  return (
    <Box
      id={`${id}-body`}
      display={isOpen ? 'block' : 'none'}
      aria-labelledby={id}
      isOpen={isOpen}
    >
      {children}
    </Box>
  );
}

CollapseBody.defaultProps = {
  id: undefined,
  isOpen: false,
  children: '',
};

CollapseBody.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  id: PropTypes.string,
  isOpen: PropTypes.bool,
};
