import React from 'react';
import PropTypes from 'prop-types';

import Navigation from './patterns/Navigation/Navigation';
import Company from './patterns/Company/Company';
import { FooterWrapper, Section } from './styles/FooterWrappers';

function Footer({ children, className }) {
  return (
    <FooterWrapper className={className}>
      { children }
    </FooterWrapper>
  );
}

Footer.Navigation = Navigation;
Footer.Company = Company;
Footer.Section = Section;

Footer.defaultProps = {
  className: undefined,
};

Footer.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default Footer;
