import React from 'react';
import PropTypes from 'prop-types';

import { SVGWrapper } from './styles/SVGWrapper';

const SMALL = 'small';
const DEFAULT = 'default';

const sizes = {
  [DEFAULT]: {
    width: 20,
    height: 20,
  },
  [SMALL]: {
    width: 16,
    height: 16,
  },
};

const IconStructure = ({
  children,
  className,
  size,
  viewBox,
  color,
  colorVariant,
  title,
  ariaHidden,
}) => {
  const iconSize = sizes[size.toLowerCase()];
  return (
    <SVGWrapper
      xmlns="http://www.w3.org/2000/svg"
      width={iconSize.width}
      height={iconSize.height}
      fill="none"
      viewBox={viewBox}
      className={className}
      color={color}
      colorVariant={colorVariant}
      aria-hidden={ariaHidden}
      role="img"
    >
      <title>{title}</title>
      {children}
    </SVGWrapper>
  );
};

export const iconPropTypes = {
  size: PropTypes.oneOf([SMALL, DEFAULT]),
  color: PropTypes.string,
  colorVariant: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  ariaHidden: PropTypes.string,
};

IconStructure.defaultProps = {
  /* eslint-disable react/default-props-match-prop-types */
  ariaHidden: undefined,
  color: 'currentColor',
  colorVariant: 'default',
  size: DEFAULT,
  viewBox: '0 0 20 20',
  /* eslint-enable react/default-props-match-prop-types */
};

IconStructure.propTypes = {
  ...iconPropTypes,
  children: PropTypes.node.isRequired,
};

export default IconStructure;
