export default {
  svgPath: `
    <g clip-path="url(#clip0)">
      <path d="M10.5 8.586l3-3L14.914 7l-3 3 3 3-1.414 1.414-3-3-3 3L6.086 13l3-3-3-3L7.5 5.586l3 3z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 20c5.523 0 10-4.477 10-10s-4.477-10-10-10S.5 4.477.5 10s4.477 10 10 10zm0-2a8 8 0 100-16 8 8 0 000 16z"/>
    </g>
    <defs>
      <clipPath id="clip0">
      <path d="M.5 0h20v20H.5z"/>
    </clipPath>
    </defs>
  `,
  title: 'X Circle',
  viewBox: '0 0 21 20',
};
