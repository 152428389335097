import styled, { css } from '@xstyled/styled-components';

import { nuDSColor } from '../../../styles/themeUtils';
import Typography from '../../Typography/Typography';

const backgroundLinearGradient = (color, colorVariant) => css`
  background-image:
    linear-gradient(120deg, ${nuDSColor(color, colorVariant)} 0%, ${nuDSColor(color, colorVariant)} 100%),
    linear-gradient(120deg, transparent, transparent);
`;

const underlineEffect = (color, colorVariant) => css`
  background-repeat: no-repeat;
  background-position: 0 100%, 100% 100%;
  background-size: 0 8%, 100% 8%;
  transition: background-size 0.2s ease-in-out;
  ${backgroundLinearGradient(color, colorVariant)};

  &:hover, &:focus {
    text-decoration: none;
    background-size: 100% 8%, 100% 8%;
  }
`;

const variantMapStyle = {
  action: css`
    line-height: 1.35;
    display: inline-flex;
    align-items: center;
    ${({ color, colorVariant }) => underlineEffect(color, colorVariant)};
  `,
  text: css`
    &:hover {
      text-decoration: underline;
    }
    &:focus {
      text-decoration: none;
      outline-width: 2px;
      outline-style: solid;
      outline-color: ${({ color, colorVariant }) => nuDSColor(color, colorVariant)};
    }
  `,
};

export const StyledTypography = styled(Typography)`
  text-decoration: none;

  ${({ linkVariant }) => (
    variantMapStyle[linkVariant]
      ? variantMapStyle[linkVariant]
      : variantMapStyle.text
  )};
`;

StyledTypography.displayName = 'StyledTypography';

export const UnstyledLink = styled.a``;

UnstyledLink.displayName = 'UnstyledLink';
