import { requireImageFunctionDefault } from '../NuDSProvider/NuDSProvider';

const avaiableTypes = new Set(['webp']);

export default function imageSourceResolver(
  imageSource,
  { type, requireImage } = {
    type: '',
    requireImage: requireImageFunctionDefault,
  },
) {
  const hasType = Boolean(type);
  const isValidType = avaiableTypes.has(type);

  if (hasType) {
    if (!isValidType) {
      throw new Error('You must proivde a valid type');
    }
    return requireImage(imageSource, type);
  }

  return requireImage(imageSource);
}
