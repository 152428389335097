import React from 'react';
import PropTypes from 'prop-types';

import { Path } from './styles/Path';

const SMALL = 'small';
const DEFAULT = 'default';

const sizes = {
  [DEFAULT]: {
    width: 58,
    height: 32,
  },
  [SMALL]: {
    width: 44,
    height: 24,
  },
};

function NuLogo({
  variant,
  size,
}) {
  const logoSize = sizes[size.toLowerCase()];
  return (
    <svg
      width={logoSize.width}
      height={logoSize.height}
      viewBox="0 0 58 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path
        variant={variant}
        d="M10.4699 3.05246C12.304 1.12557 14.7369 0 17.5951 0C23.1632 0 26.8549 4.06542 27.5612 10.1557C27.7902 12.1335 27.7881 14.9219 27.7858 18.1516C27.7855 18.4828 27.7853 18.8187 27.7853 19.1588V31.2578H20.1281V22.309C20.1281 22.309 20.1125 14.6501 20.0657 13.2187C19.8602 6.98449 16.1695 3.06722 10.4691 3.06301C8.74925 4.88006 7.82773 7.10172 7.69183 10.4987C7.67315 10.9725 7.67785 12.6519 7.68404 14.8653C7.68725 16.0121 7.69086 17.3023 7.69183 18.6424C7.6975 24.4753 7.69183 31.2591 7.69183 31.2591H0.0346685V17.487C0.0346685 17.0151 0.0260685 16.5392 0.0174352 16.0614C6.75514e-05 15.1003 -0.0174352 14.1318 0.0346685 13.1728C0.121182 11.5741 0.397742 10.0025 1.14374 8.54252C2.85132 5.1978 6.35016 3.04426 10.0802 3.04426C10.2105 3.04426 10.3409 3.047 10.4699 3.05246Z"
      />
      <Path
        variant={variant}
        d="M57.9653 18.8272C58.0174 17.8682 57.9999 16.8996 57.9826 15.9386C57.9739 15.4608 57.9653 14.9849 57.9653 14.513V0.740867H50.3082C50.3082 0.740867 50.3025 7.52467 50.3082 13.3575C50.3091 14.6976 50.3127 15.9877 50.3159 17.1345C50.3221 19.348 50.3268 21.0274 50.3082 21.5012C50.1723 24.8983 49.2507 27.1199 47.5309 28.937C41.8306 28.9327 38.14 25.0154 37.9344 18.7813C37.8876 17.3499 37.8678 13.7978 37.8678 9.68381V0.735023L30.2163 0.742173V12.8412C30.2163 13.1813 30.216 13.5171 30.2158 13.8484C30.2134 17.0781 30.2114 19.8664 30.4404 21.8443C31.1452 27.9346 34.8369 32 40.405 32C43.2633 32 45.6962 30.8744 47.5303 28.9475C47.6592 28.953 47.7895 28.9557 47.9198 28.9557C51.6498 28.9557 55.1487 26.8022 56.8563 23.4575C57.6023 21.9974 57.8788 20.4259 57.9653 18.8272Z"
      />
    </svg>
  );
}

NuLogo.defaultProps = {
  size: DEFAULT,
  variant: 'white',
};

NuLogo.propTypes = {
  size: PropTypes.oneOf([SMALL, DEFAULT]),
  variant: PropTypes.oneOf(['white', 'primary', 'black']),
};

export default NuLogo;
