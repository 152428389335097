import styled from '@xstyled/styled-components';

export const Header = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
  width: 100%;
  background-color: transparent;
  text-align: left;
  cursor: pointer;
`;
