import React from 'react';
import PropTypes from 'prop-types';

import Typography from '../../../../components/Typography/Typography';

import { CompanyInfoContainer } from './styles/CompanyWrappers';

function CompanyInfo({ children }) {
  return (
    <CompanyInfoContainer>
      <Typography
        color="white.dark"
        variant="paragraph1"
        strong
      >
        {children}
      </Typography>
    </CompanyInfoContainer>
  );
}

CompanyInfo.propTypes = {
  children: PropTypes.node,
};

CompanyInfo.defaultProps = {
  children: null,
};

export default CompanyInfo;
