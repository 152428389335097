export default function maybeFormatMessage(
  intl,
  keyPrefix,
  keySuffix,
  values,
) {
  const intlKey = keySuffix
    ? [keyPrefix, keySuffix].join('.')
    : keyPrefix;

  return intl.messages[intlKey]
    ? intl.formatMessage({
      id: intlKey,
    }, values)
    : null;
}
