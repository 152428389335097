import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import Icon from '../Icon/Icon';
import Typography from '../Typography/Typography';
import withIntlAriaAttrs from '../../hoc/withIntlAriaAttrs';

import { BaseButton } from './styles/BaseButton';
import Wave from './components/Wave/Wave';
import { useRipple } from './components/Wave/useRipple';

const ENTER_KEY_CODE = 13;
const SPACEBAR_KEY_CODE = 32;
const keyCodesAllowedSet = new Set([ENTER_KEY_CODE, SPACEBAR_KEY_CODE]);

const typographyVariant = {
  default: 'button',
  small: 'paragraph2',
};

function executeRippleEffect(
  {
    startFromMiddle = false,
    target,
    clientX,
    clientY,
  },
  addRippleEffectWaveFn,
) {
  const buttonPosition = target.getBoundingClientRect();
  addRippleEffectWaveFn(
    {
      startFromMiddle,
      baseElementBoundingClientRect: buttonPosition,
      mousePosition: {
        clientX,
        clientY,
      },
    },
  );
}

const Button = forwardRef(({
  type,
  children,
  styleVariant,
  extended,
  size,
  iconProps,
  variant,
  typographyProps,
  disabled,
  intlKey,
  onClick,
  ...props
}, ref) => {
  const { addRippleEffectWave, removeWaveById, waves } = useRipple();
  const hasIcon = Boolean(iconProps);
  const hasText = Boolean(children) || intlKey || Boolean(typographyProps);
  const isIconOnly = (hasIcon && !hasText);

  const handleOnMouseDown = event => {
    executeRippleEffect(
      {
        target: event.currentTarget,
        clientX: event.clientX,
        clientY: event.clientY,
      },
      addRippleEffectWave,
    );
  };

  const handleKeydown = event => {
    if (keyCodesAllowedSet.has(event.keyCode)) {
      executeRippleEffect(
        {
          startFromMiddle: true,
          target: event.target,
        },
        addRippleEffectWave,
      );
    }
  };

  return (
    <BaseButton
      ref={ref}
      type={type}
      variant={variant}
      styleVariant={styleVariant}
      extended={extended}
      size={size}
      isIconOnly={isIconOnly}
      hasText={hasText}
      hasIcon={hasIcon}
      disabled={disabled}
      onClick={onClick}
      onMouseDown={handleOnMouseDown}
      onKeyDown={handleKeydown}
      {...props}
    >
      {hasText && (
        <Typography
          {...typographyProps}
          variant={typographyVariant[size]}
          strong
          tag="span"
          intlKey={intlKey}
        >
          {children}
        </Typography>
      )}
      {hasIcon && <Icon aria-hidden={hasText} {...iconProps} size="small" />}
      {waves.map(currentWave => (
        <Wave
          key={currentWave.id}
          id={currentWave.id}
          top={currentWave.top}
          left={currentWave.left}
          scale={currentWave.scale}
          removeWaveById={removeWaveById}
        />
      ))}
    </BaseButton>
  );
});

Button.defaultProps = {
  extended: false,
  type: 'button',
  size: 'default',
  styleVariant: 'primary',
  onClick: undefined,
  variant: 'contained',
  typographyProps: null,
  iconProps: null,
  disabled: false,
  intlKey: '',
  children: null,
};

Button.propTypes = {
  /** The content of the component, it can be either another component or raw text */
  children: PropTypes.node,
  /** Defines if the button is disabled or not */
  disabled: PropTypes.bool,
  /** Defines is the button will have the full width of the parent component */
  extended: PropTypes.bool,
  /** The props of the Icon component, check it to see the available props. */
  iconProps: PropTypes.shape({
    ...Icon.propTypes,
  }),
  /** An i18n key that will be used to search for content */
  intlKey: PropTypes.string,
  /** A function that will be called when the button receives a click */
  onClick: PropTypes.func,
  /** Defines the size of the button */
  size: PropTypes.oneOf(['default', 'small']),
  /** The visual style that works combined with the chosen variant */
  styleVariant: PropTypes.oneOf(['primary', 'black', 'white']),
  /** The type of the button */
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  /** The props of the Typography component, check it to see the available props. */
  typographyProps: PropTypes.shape({
    ...Typography.propTypes,
  }),
  /** The base style of the component */
  variant: PropTypes.oneOf(['contained', 'basic']),
};

export default withIntlAriaAttrs(Button);
