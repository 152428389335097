import {
  up,
  down,
  between,
  breakpoints as breakpointsMediaAll,
} from '@xstyled/system';
import {
  css,
  useBreakpoints,
  useBreakpoint,
  useUp,
  useDown,
  useViewportWidth as useViewport,
} from '@xstyled/styled-components';

import { logDeprecatedFeature } from '../utils/logger/logDeprecatedFeature/logDeprecatedFeature';

// Default Breakpoints
export const DEFAULT_BREAKPOINTS = {
  xs: 0,
  sm: 360,
  md: 768,
  lg: 1152,
  xl: 1366,
};

// breakpoints is a utility to create mobile first responsive components
export const breakpointsMedia = (...args) => breakpointsMediaAll(...args);
export const breakpointsMediaUp = (...args) => up(...args);
export const breakpointsMediaDown = (...args) => down(...args);
export const breakpointsMediaBetween = (...args) => between(...args);

// Return the current breakpoint (based on viewport width)
export const useBreakpointMedia = () => useBreakpoint();
// Return breakpoints defined in the theme (or the default breakpoints)
export const useBreakpointsMedia = () => useBreakpoints();
// Return true if the viewport width is greater than the specified breakpoint
export const useBreakpointsMediaUp = breakpointName => useUp(breakpointName);
// Return true if the viewport width is lower than the specified breakpoint
export const useBreakpointsMediaDown = breakpointName => useDown(breakpointName);
// Return the width of the viewport
export const useViewportWidth = () => useViewport();

// TODO: remove when replace breakpoints will be removed
export const DEPRECATED_BREAKPOINTS = {
  mobile320: 320,
  mobile480: DEFAULT_BREAKPOINTS.sm,
  tablet768: DEFAULT_BREAKPOINTS.md,
  desktop1024: DEFAULT_BREAKPOINTS.lg,
  desktop1440: DEFAULT_BREAKPOINTS.xl,
};

const media = (size, args) => css`
  ${logDeprecatedFeature('You must to use the new version of breakpoints.')}
  @media screen and (min-width: ${size}px) {
    ${css(...args)};
  }
`;

export const mobile320 = (...args) => media(DEPRECATED_BREAKPOINTS.mobile320, args);
export const mobile480 = (...args) => media(DEPRECATED_BREAKPOINTS.mobile480, args);
export const tablet768 = (...args) => media(DEPRECATED_BREAKPOINTS.tablet768, args);
export const desktop1024 = (...args) => media(DEPRECATED_BREAKPOINTS.desktop1024, args);
export const desktop1440 = (...args) => media(DEPRECATED_BREAKPOINTS.desktop1440, args);
