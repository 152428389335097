import theme from '../../styles/theme';

const { breakpoints } = theme;
const breakpointsNames = Object.keys(breakpoints).reverse();

const pixelDensities = ['1x', '2x', '3x'];

function createSource(path = '', pixelDensity) {
  if (pixelDensity) {
    return {
      path,
      pixelDensity,
    };
  }

  return {
    path,
  };
}

function createBreakPointWithSources(providedSourceForBreakpoint, breakPointValue) {
  return {
    breakPointValue,
    sources: pixelDensities.reduce((acc, pixelDensity) => {
      const pixelDensitySource = providedSourceForBreakpoint[pixelDensity];

      if (!pixelDensitySource) return acc;

      return [
        ...acc,
        createSource(pixelDensitySource, pixelDensity),
      ];
    }, []),
  };
}

function createBreakPointWithSource(providedSourceForBreakpoint, breakPointValue) {
  const source = createSource(providedSourceForBreakpoint);
  return {
    breakPointValue,
    source,
  };
}

export function getSmallerSourcePathFrom(sourcesToRenderByBreakpoint) {
  const smallerSourceIndex = sourcesToRenderByBreakpoint.length - 1;
  const smallerImageFromSource = sourcesToRenderByBreakpoint[smallerSourceIndex]?.source;
  const smallerImageFromSources = (
    sourcesToRenderByBreakpoint[smallerSourceIndex]?.sources
    && sourcesToRenderByBreakpoint[smallerSourceIndex]?.sources[0]
  );

  return smallerImageFromSource?.path || smallerImageFromSources?.path;
}

export default function prepareSourcesToRender(srcSet = {
  xl: '',
  lg: '',
  md: '',
  sm: '',
  xs: '',
}) {
  return breakpointsNames.reduce((accSources, breakpointName) => {
    const providedSourceForBreakpoint = srcSet[breakpointName];
    const hasProvidedSourceForBreakpoint = Boolean(providedSourceForBreakpoint);
    const breakPointValue = breakpoints[breakpointName];

    if (!hasProvidedSourceForBreakpoint) return accSources;

    const hasMultiSourcesForCurrentBreakpoint = typeof providedSourceForBreakpoint === 'object';

    if (hasMultiSourcesForCurrentBreakpoint) {
      return [
        ...accSources,
        createBreakPointWithSources(providedSourceForBreakpoint, breakPointValue),
      ];
    }

    return [
      ...accSources,
      createBreakPointWithSource(providedSourceForBreakpoint, breakPointValue),
    ];
  }, []);
}
