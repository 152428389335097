import React from 'react';
import { getSystemPropTypes } from '@xstyled/prop-types';
import {
  compose,
  layout,
  textAlign,
  style,
  overflow,
  space,
  variant,
  getSpace,
  createSystemComponent,
  createStyleGenerator,
  getColor,
} from '@xstyled/system';
import styled, { css } from '@xstyled/styled-components';

import { breakpointsMedia } from '../../../styles/breakpoints';
import { nuDSColor } from '../../../styles/themeUtils';
import { isType } from '../../../utils/isType/isType';

const textDecoration = style({
  prop: 'textDecoration',
  cssProperty: 'textDecoration',
});

const textOverflow = style({
  prop: 'textOverflow',
  cssProperty: 'textOverflow',
});

const wordBreak = style({
  prop: 'wordBreak',
  cssProperty: 'wordBreak',
});

const gutterBottom = style({
  prop: 'gutterBottom',
  cssProperty: 'marginBottom',
  themeGet: propValue => () => propValue * 16,
});

const whiteSpace = style({
  prop: 'whiteSpace',
  cssProperty: 'whiteSpace',
});

const marginHorizontal = style({
  prop: 'marginHorizontal',
  cssProperty: ['marginRight', 'marginLeft'],
  themeGet: getSpace,
});

const marginVertical = style({
  prop: 'marginVertical',
  cssProperty: ['marginTop', 'marginBottom'],
  themeGet: getSpace,
});

export const paddingHorizontal = style({
  prop: 'paddingHorizontal',
  cssProperty: ['paddingRight', 'paddingLeft'],
  themeGet: getSpace,
});

export const paddingVertical = style({
  prop: 'paddingVertical',
  cssProperty: ['paddingTop', 'paddingBottom'],
  themeGet: getSpace,
});

const color = style({
  prop: 'color',
  themeGet: getColor,
});

const legacyColor = createStyleGenerator(
  props => ({
    color: `${nuDSColor(props.color, props.colorVariant)()}`,
  }),
  ['color'],
);

export const styleProps = compose(
  layout,
  textAlign,
  textDecoration,
  textOverflow,
  overflow,
  wordBreak,
  space,
  whiteSpace,
  marginHorizontal,
  marginVertical,
  paddingHorizontal,
  paddingVertical,
  color,
);

function appendDefaultFontFamily(newFontFamily) {
  // eslint-disable-next-line quotes
  const defaultFonts = `'Graphik-Regular', 'Gotham SSm A', 'Gotham SSm B', 'Helvetica Neue', Helvetica, Arial,sans-serif`;

  return newFontFamily
    ? `${newFontFamily}, ${defaultFonts}`
    : defaultFonts;
}

export const defaultStrong = ['supertitle', 'heading1', 'heading2', 'heading3', 'heading4', 'button', 'overline'];

const stylesByVariantMap = {
  supertitle: breakpointsMedia({
    xs: css`
      font-weight: 500;
      letter-spacing: -0.03em;
      font-size: 2.75rem;
      line-height: 1.1;
    `,
    md: css`
      font-size: 5.75rem;
      line-height: 1.04;
    `,
  }),
  heading1: breakpointsMedia({
    xs: css`
      font-weight: 500;
      letter-spacing: -0.03em;
      font-size: 2.25rem;
      line-height: 1.16;
    `,
    md: css`
      font-size: 4rem;
      line-height: 1;
    `,
  }),
  heading2: breakpointsMedia({
    xs: css`
      font-weight: 500;
      letter-spacing: -0.03em;
      font-size: 1.875rem;
      line-height: 1.2;
    `,
    md: css`
      font-size: 3rem;
      line-height: 1.16;
    `,
  }),
  heading3: breakpointsMedia({
    xs: css`
      font-weight: 500;
      letter-spacing: -0.02em;
      font-size: 1.5rem;
      line-height: 1.32;
    `,
    md: css`
      letter-spacing: -0.03em;
      font-size: 2.5rem;
      line-height: 1.2;
    `,
  }),
  heading4: breakpointsMedia({
    xs: css`
      font-weight: 500;
      letter-spacing: -0.02em;
      font-size: 1.25rem;
      line-height: 1.22;
    `,
    md: css`
      font-size: 1.5rem;
      line-height: 1.32;
    `,
  }),
  subtitle1: breakpointsMedia({
    xs: css`
      letter-spacing: -0.01em;
      font-size: 1.125rem;
      line-height: 1.32;
    `,
  }),
  subtitle2: breakpointsMedia({
    xs: css`
      letter-spacing: -0.01em;
      font-size: 1rem;
      line-height: 1.25;
    `,
  }),
  paragraph1: breakpointsMedia({
    xs: css`
      letter-spacing: -0.01em;
      font-size: 1rem;
      line-height: 1.5;
    `,
  }),
  paragraph2: breakpointsMedia({
    xs: css`
      letter-spacing: -0.01em;
      font-size: 0.875rem;
      line-height: 1.46;
    `,
  }),
  button: breakpointsMedia({
    xs: css`
      letter-spacing: -0.01em;
      font-size: 1rem;
      line-height: 1;
    `,
  }),
  caption: breakpointsMedia({
    xs: css`
      letter-spacing: -0.01em;
      font-size: 0.875rem;
      line-height: 1.25;
    `,
  }),
  overline: breakpointsMedia({
    xs: css`
      font-size: 0.75rem;
      line-height: 1.35;
      text-decoration: uppercase;
    `,
  }),
};

/* stylelint-disable value-no-vendor-prefix, property-no-vendor-prefix */
const createEllipsisStyle = lines => css`
  display: -webkit-box;
  -webkit-line-clamp: ${lines};
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: normal;
`;
/* stylelint-enable value-no-vendor-prefix, property-no-vendor-prefix */

const ellipsis = ({ lines }) => {
  if (!lines) return null;
  if (isType('object', lines)) {
    const stylesByBreakPoints = Object.keys(lines).reduce(
      (prev, key) => ({
        ...prev,
        [key]: createEllipsisStyle(lines[key]),
      }), {},
    );
    return breakpointsMedia(stylesByBreakPoints);
  }
  return createEllipsisStyle(lines);
};

const InnerTypography = createSystemComponent(React, 'span', styleProps);
export const TypographyWrapper = styled(InnerTypography).withConfig({
  shouldForwardProp: (prop, validate) => validate(prop) && !styleProps.meta.props.includes(prop),
})`
  ${({ strong }) => (strong
    ? `font-family: ${appendDefaultFontFamily('Graphik-Medium')};`
    : `font-family: ${appendDefaultFontFamily()};`)}
  margin: 0;

  ${ellipsis}
  ${gutterBottom}
  ${styleProps}
  ${variant({
    variants: stylesByVariantMap,
  })}
  ${({ hasLegacyColor }) => hasLegacyColor && css`${legacyColor}`}
`;

TypographyWrapper.displayName = 'TypographyWrapper';
TypographyWrapper.propTypes = getSystemPropTypes(styleProps);
