import styled from 'styled-components';

import { tablet768 } from '../../../../../styles/breakpoints';

export const Column = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;

  &:last-of-type {
    margin-bottom: 0;
  }

  ${tablet768`
    margin-bottom: 0;
  `}
`;

Column.displayName = 'Column';
