import styled from '@xstyled/styled-components';
import PropTypes from 'prop-types';

import { nuDSColor } from '../../../styles/themeUtils';

export const Path = styled.path`
  fill: ${({ variant }) => nuDSColor(variant)};
`;

Path.displayName = 'Path';

Path.propTypes = {
  variant: PropTypes.string.isRequired,
};
