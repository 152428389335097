import { isBrowserEnv } from '../../env/isBrowserEnv/isBrowserEnv';
import { isProdEnv } from '../../env/isProdEnv/isProdEnv';

const loggedMessages = new Set();

export function logDeprecatedFeature(message) {
  if (!isBrowserEnv) return undefined;
  if (isProdEnv) return undefined;

  const logMessage = `[NuDS/Deprecated] ${message}`;

  if (!loggedMessages.has(logMessage)) {
    console.warn(logMessage);
    loggedMessages.add(logMessage);
  }

  return undefined;
}
