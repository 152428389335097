import styled from '@xstyled/styled-components';

import { nuDSColor } from '../../../../../styles/themeUtils';

export const SVGWrapper = styled.svg`
  path {
    fill: ${({ color, colorVariant, ...theme }) => (color === 'currentColor'
    ? color
    : nuDSColor(color, colorVariant)(theme))};
  }
`;
