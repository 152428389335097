import styled from 'styled-components';

import { tablet768, desktop1440 } from '../../../../../styles/breakpoints';
import { sectionStyle } from '../../../styles/commonFooterStyles';

export const NavigationWrapper = styled.div`
  ${sectionStyle};

  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 48px;

  ${tablet768`
    grid-template-columns: 1fr 1fr;
  `}

  ${desktop1440`
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-row-gap: 80px;
  `}
`;

NavigationWrapper.displayName = 'NavigationWrapper';
