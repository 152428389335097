/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';

import IconStructure from './components/IconStructure/IconStructure';
import icons from './icons';

const iconNames = Object.keys(icons);

const Icon = ({
  color,
  colorVariant,
  name,
  title,
  size,
  className,
  ariaHidden,
}) => {
  const icon = icons[name];
  const iconTitle = title || icon.title;

  return (
    <IconStructure
      iconName={name}
      title={iconTitle}
      color={color}
      colorVariant={colorVariant}
      aria-labelledby={iconTitle}
      size={size}
      viewBox={icon.viewBox}
      className={className}
      ariaHidden={ariaHidden}
    >
      <g dangerouslySetInnerHTML={{ __html: icon.svgPath }} />
    </IconStructure>
  );
};

Icon.defaultProps = {
  ariaHidden: undefined,
  color: 'currentColor',
  colorVariant: 'default',
  size: IconStructure.defaultProps.size,
  title: undefined,
  className: '',
};

Icon.propTypes = {
  ariaHidden: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
  colorVariant: PropTypes.string,
  name: PropTypes.oneOf(iconNames).isRequired,
  size: IconStructure.propTypes.size,
  title: PropTypes.string,
};

export default Icon;
